import React, { useEffect } from 'react'
import tw, { styled } from 'twin.macro'
import { GatsbyImage } from 'gatsby-plugin-image'

const StyledSection = styled.section`
  .item img {
    pointer-events: none;
  }
  .item {
    ${tw`mt-2`}
  }
  .activeQoute {
    transform: scale(105%);
  }

  .carousel {
    scroll-snap-type: x mandatory;
    display: flex;
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;

    scroll-behavior: smooth;
    scrollbar-width: thin;
    scrollbar-color: #333 transparent;
    -ms-overflow-style: none;
    margin-bottom: 2vw;
    transition: all 300ms ease-in-out;
  }
  .carousel::-webkit-scrollbar {
    width: 0;
    background: #f1f1f1;
    height: 6px;
  }
  .carousel::-webkit-scrollbar-thumb {
    background: #333;
  }
  .carousel > div {
    min-width: auto;
    scroll-snap-align: start;
    position: relative;
  }
  .carousel > div:last-of-type {
    min-width: 100%;
  }
  // @media (max-width: 768px) {
  //   .carousel > div {
  //     min-width: 100%;
  //   }
  // }
  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`

const StyledArrowsDiv = styled.div`
  .prev,
  .next {
    position: relative;
  }
  .prev::before,
  .prev::after {
    left: -35px;
    bottom: 9px;
    position: absolute;
    content: '';
  }
  .prev::before {
    width: 30px;
    height: 2px;
    background: black;
  }
  .prev::after {
    border: solid black;
    padding: 3px;
    border-width: 0 2px 2px 0;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    margin-bottom: -3px;
  }
  .next::before,
  .next::after {
    right: -35px;
    bottom: 9px;
    position: absolute;
    content: '';
  }
  .next::before {
    width: 30px;
    height: 2px;
    background: black;
  }
  .next::after {
    border: solid black;
    padding: 3px;
    border-width: 0 2px 2px 0;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    margin-bottom: -3px;
  }
`

export default function Carousel(props) {
  useEffect(() => {
    const slider = document.querySelector('.items')
    let isDown = false
    let startX
    let scrollLeft

    slider?.addEventListener('mousedown', (e) => {
      isDown = true
      slider.classList.add('activeQoute')
      startX = e.pageX - slider.offsetLeft
      scrollLeft = slider.scrollLeft
    })
    slider?.addEventListener('mouseleave', () => {
      isDown = false
      slider.classList.remove('activeQoute')
    })
    slider?.addEventListener('mouseup', () => {
      isDown = false
      slider.classList.remove('activeQoute')
    })
    slider?.addEventListener('mousemove', (e) => {
      if (!isDown) return
      e.preventDefault()
      const x = e.pageX - slider.offsetLeft
      const walk = (x - startX) * 3 //scroll-fast
      slider.scrollLeft = scrollLeft - walk
    })

    // const buttonLeft = document.getElementById('slideLeft')
    // const buttonRight = document.getElementById('slideRight')
    //
    // buttonRight.onclick = function () {
    //   document.getElementById('snap').scrollLeft += 600
    // }
    // buttonLeft.onclick = function () {
    //   document.getElementById('snap').scrollLeft -= 600
    // }
  })

  return (
    <>
      <StyledSection className="mm-mx-dynamic">
        <div className="scroll statement carousel items noselect" id="snap">
          {props.productCarousel.map((item, i) => (
            <div key={i} className="item">
              {item.bild[0]?.localFile?.childImageSharp?.gatsbyImageData && (
                <GatsbyImage
                  image={
                    item.bild[0]?.localFile?.childImageSharp?.gatsbyImageData
                  }
                  css={tw`mr-10 mb-2`}
                  alt={item.bild[0]?.title}
                />
              )}

              <div
                className="noselect"
                css={tw`flex flex-col justify-center max-w-sm`}
              >
                <h4>{item.heading}</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.text,
                  }}
                />
              </div>
            </div>
          ))}
        </div>
        <StyledArrowsDiv
          css={tw`hidden md:flex justify-between font-bold text-sm`}
          className="mm-mx-dynamic"
        >
          <span className="prev" id="slideLeft">
            TILBAGE
          </span>
          <span className="next" id="slideRight">
            NÆSTE
          </span>
        </StyledArrowsDiv>
      </StyledSection>
    </>
  )
}
